/* eslint-disable */
/**
 * Trust All Scripts
 *
 * This is a dirty little script for iterating over script tags
 * of your Ghost posts and adding them to the document head.
 *
 * This works for any script that then injects content into the page
 * via ids/classnames etc.
 *
 */
var trustAllScripts = function () {
    var scriptNodes = document.querySelectorAll(".load-external-scripts script");

    for (var i = 0; i < scriptNodes.length; i += 1) {
      var node = scriptNodes[i];
      var s = document.createElement("script");
      s.type = node.type || "text/javascript";

      if (node.attributes.src) {
        s.src = node.attributes.src.value;
      } else {
        s.innerHTML = node.innerHTML;
      }

      document.getElementsByTagName("head")[0].appendChild(s);
    }
  };

  exports.onInitialClientRender = function () {
    const utms = window.location.search;
    if (utms) {
      document.querySelectorAll(".nav-list > a").forEach((a) => {
        const current = a.getAttribute("href");
        const urlStipped = current.slice(0, current.lastIndexOf("/"));
        const url = urlStipped + utms;
        a.setAttribute("href", url);
      });
    }
  };

  exports.onRouteUpdate = function () {
    trustAllScripts();
  };
